*{
    box-sizing: border-box;
}

*:focus,
*.focus {
	outline: 0 transparent !important;
}
          
/* MAIN STRUCT */   
html{        
    height: 100%; 
    font-size:16px; 
}

body{
    min-height: 100%;
    height: 100%;
    position: relative;
    margin: 0;
    overflow-x: hidden;
    font-family: 'Open Sans', sans-serif;
}

#root{
    @extend body;
}

// BLIP CHAT
#blip-chat-container{
  #blip-chat-open-iframe{
    width: 40px!important;
    height: 40px!important;
    @media (max-width:1279px) {
      min-width: 40px!important;
      min-height: 40px!important;
    }
  }
}